import ProfileCard from "./ProfileCard";


const AboutMe = () => {


    return ( 

        <ProfileCard/>

     );
}
 
export default AboutMe;

    
